import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MDXProvider } from '@mdx-js/react';

import LayoutContext from './layoutContext';
import Donate from '../components/donate/donate';
import Toc from '../components/toc';
import DownloadCount from '../components/donate/downloadCount';
import LastBuild from './software/lastBuild';
import DownloadLink from './software/downloadLink';
import DownloadTitle from './software/downloadTitle';
import Adv from './software/adv';
import BannerCreate from './software/bannerCreate';
import TutorialHomeBanner from './tutorial/tutorialHomeBanner';
import Iubenda from './privacy-policy/privacy-policy';

import '../styles/index.scss';
import './layout.scss';

// eslint-disable-next-line
import i18n from '../i18n';

const components = {
  LastBuild,
  DownloadLink,
  DownloadTitle,
  Adv,
  BannerCreate,
  Donate,
  DownloadCount,
  Iubenda,
  Toc,
  TutorialHomeBanner,
  // MDX is stoopid and prefixes all urls with assetPrefix
  // https://github.com/gatsbyjs/gatsby/issues/21462
  a: props => {
    const { children, href, ...otherProps } = props;
    let newHref = href;
    if (href.startsWith('/wiki')) {
      newHref = href.slice('/wiki'.length);
    }

    const allProps = { ...otherProps, href: newHref };
    return (
      <a rel="noopener noreferrer" {...allProps}>
        {children}
      </a>
    );
  },
};

const Layout = ({ children, currentLocale, withMobileToc, location }) => {
  const [isEmbedded, setEmbedded] = useState(null);

  const layoutCtx = {
    isEmbedded,
    withMobileToc,
  };

  // Detect if the wiki is supposed to be embedded (in an iframe, or if ?embed was specified)
  useEffect(() => {
    setEmbedded(window.self !== window.top || window.location.href.includes('?embed'));
  }, []);

  // Listen for locale changes
  useEffect(() => {
    i18n.changeLanguage(currentLocale);
  }, [currentLocale]);

  useEffect(() => {
    if (window && window.arduinoHeader) {
      // it will work fine in production with location.href but for the sake of the dev/stg envs the origin is hardcoded
      window.arduinoHeader.setActiveLink('https://www.arduino.cc' + window.location.pathname);
    }
  }, [location]);

  return (
    <LayoutContext.Provider value={layoutCtx}>
      <MDXProvider components={components}>
        <div
          className={classnames({
            'layout-container': true,
            'with-mobile-toc': withMobileToc,
          })}
        >
          {children}
        </div>
      </MDXProvider>
    </LayoutContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  currentLocale: PropTypes.string,
  withMobileToc: PropTypes.bool,
};

export default Layout;
