import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import './adv.scss';

const Adv = () => {
  useEffect(() => {
    if (!window) {
      return;
    }
    const interval = setInterval(() => {
      if (window.injectFirstTimeArduinoAdv && !window.advIsInitialized) {
        window.injectFirstTimeArduinoAdv();
        // we can't use React' state, or the banner would be destroyed at the first re-render
        window.advIsInitialized = true;
      }
    }, 500);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
                var arduinoAdvOptions = {
                    breakpointDesktop: 960,
                    breakpointMobile: 420,
                    zoneIdDesktop: 556,
                    zoneIdTablet: 558,
                    zoneIdMobile: 557
                };`}
        </script>
        <script
          type="text/javascript"
          src="https://cdnjs.cloudflare.com/ajax/libs/postscribe/2.0.8/postscribe.min.js"
        ></script>
        <script async type="text/javascript" src="https://content.arduino.cc/banner/banner.js"></script>
      </Helmet>
      <div id="adv-wrapper">
        <div id="ad_container_zone" className="adv-banner"></div>
      </div>
    </>
  );
};

export default Adv;
