import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { navigate } from '@reach/router';

import { triggerDownload } from '../../utils/download';

import './downloadLink.scss';

const downloadsBaseURL = 'https://downloads.arduino.cc';

const DownloadLink = ({ href, children, askForDonation, title, description, inline, badge }) => {
  const directDownload = href.indexOf('http') === 0 ? href : `${downloadsBaseURL}${href}`;

  const download = e => {
    if (askForDonation) {
      navigate('/en/donate/', { state: { downloadUrl: directDownload } });
    } else {
      triggerDownload(directDownload);
    }
    e.preventDefault();
  };

  return (
    <a
      className={classnames({
        'download-link': true,
        'inline-download-link': inline,
        'with-badge': !!badge,
      })}
      title={[title, description].join(' ')}
      href={directDownload}
      onClick={download}
    >
      {title ? <span className="title">{title} </span> : null}
      <span className="description">{description}</span>
      {badge ? <img className="badge" src={badge} alt="title" /> : null}
      {children}
    </a>
  );
};

DownloadLink.propTypes = {
  href: PropTypes.string,
  askForDonation: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default DownloadLink;
