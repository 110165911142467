import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './privacy-policy.scss';

const Iubenda = ({ url }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(url);
      const body = await res.json();

      let content = body.content;
      content = content.replaceAll('h4', 'h5');
      content = content.replaceAll('h3', 'h4');

      setContent(content);
    };

    fetchData();
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
};

Iubenda.propTypes = {
  url: PropTypes.string,
};

export default Iubenda;
