export default {
  common: {
    lastRevision: 'Last revision',
    toc: 'Table of Contents',
  },
  languageSelector: {
    changeLanguage: 'Change Language',
    available: 'This page is available in another language. Switch to: ',
  },
  donate: {
    amount: 'Amount',
    continue: 'CONTINUE',
    contributeAndDownload: 'CONTRIBUTE AND DOWNLOAD',
    contribution: 'Contribution',
    contributionDescription: 'Please enter the amount you would like to contribute',
    creditCard: 'Credit Card',
    justDownload: 'JUST DOWNLOAD',
    paypal: 'PayPal',
    invalidAmountMessage: 'Invalid amount',
    pleaseDonateMore: 'Minimum US$1,50',
    unexpectedError: 'Server unavailable or unexpected error, please retry.',
  },
  software: {
    lastUpdate: 'LAST UPDATE',
    createBanner: {
      title: 'Arduino Cloud Editor',
      description:
        "Experience the Arduino IDE online. Whether you're at home or on the go, code, upload and access your projects anytime from your browser <b>for free.</b>",
      cta: 'Go to Cloud Editor',
      gettingStarted: 'Learn more',
    },
  },
  crumbs: {
    Guide: 'Getting Started',
    BuiltInExamples: 'Built-In Example',
    LibraryExamples: 'Examples from Libraries',
    Foundations: 'Foundations',
  },
};
