import React, { useState } from 'react';
import { navigate } from '@reach/router';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '@bcmi-labs/react-components';

import ErrorMessage from './errorMessage';

import '../../styles/index.scss';
import './donate.scss';
import donateImg from '../../assets/donate.png';

const donationSteps = [3, 5, 10, 25, 50];

const Donate = ({ title, downloadUrl, children }) => {
  const { t } = useTranslation();

  const [amount, setAmount] = useState(5);
  const [customAmountError, setCustomAmountError] = useState();

  const validateAmount = () => {
    if (!amount) {
      setAmount(0);
      return false;
    }
    return true;
  };

  const validateCustomAmount = val => {
    if (!val) {
      setCustomAmountError(null);
      setAmount(0);
      return false;
    }
    const numeric = parseFloat(val.replace(',', '.'));
    if (isNaN(numeric)) {
      setCustomAmountError(t('donate:invalidAmountMessage'));
      return false;
    }
    if (numeric < 1.5) {
      setCustomAmountError(t('donate:pleaseDonateMore'));
      return false;
    }
    setCustomAmountError(null);
    setAmount(numeric);
    return true;
  };

  const onChange = e => {
    validateCustomAmount(e.target.value);
  };

  const confirm = () => {
    navigate('/en/donate/confirm', { state: { amount, downloadUrl } });
  };

  return (
    <div className="donate">
      <h2>{title}</h2>
      <div className="donate-body">{children}</div>
      <div className="donate-amount">
        {donationSteps.map(s => (
          <div
            className={classnames({ 'set-amount': true, selected: s == amount })}
            role="button"
            tabIndex="0"
            key={`d-${s}`}
            onClick={() => {
              setAmount(s);
            }}
            onKeyPress={() => {
              setAmount(s);
            }}
          >
            ${s}
          </div>
        ))}
        <div className="custom-amount">
          <Input label="Other" placeholder="$ Amount" onChange={onChange} onBlur={onChange} error={customAmountError} />
        </div>
      </div>
      {amount === 0 ? <ErrorMessage>Please select an amount to donate.</ErrorMessage> : null}
      <div className={classnames({ 'download-buttons': true, 'with-download': !!downloadUrl })}>
        <Button onPress={() => validateAmount() && confirm()}>
          {t(downloadUrl ? 'donate:contributeAndDownload' : 'donate:continue')}
        </Button>

        {downloadUrl ? (
          <>
            <span className="download-buttons__or">or</span>
            <Button
              variant="tertiary"
              onPress={() => {
                navigate('/en/donate/newsletter', { state: { downloadUrl: downloadUrl } });
              }}
            >
              {t('donate:justDownload')}
            </Button>
          </>
        ) : null}
      </div>
      <div className="donate-footer">
        <img src={donateImg} alt="donate" />
        <hr />
        Learn more about{' '}
        <a href="https://support.arduino.cc/hc/en-us/sections/360004802200-Donate-to-Arduino">donating to Arduino</a>.
      </div>
    </div>
  );
};

Donate.propTypes = {
  url: PropTypes.string,
};

export default Donate;
