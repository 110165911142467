export default {
  common: {
    lastRevision: 'Ultima revisione',
    toc: 'Contenuti',
  },
  languageSelector: {
    changeLanguage: 'Cambia Lingua',
    available: "Questa pagina è disponibile in in un'altra lingua. Cambia in: ",
  },
  software: {
    lastUpdate: 'LAST UPDATE',
    createBanner: {
      title: 'Arduino Cloud Editor',
      description:
        "Experience the Arduino IDE online. Whether you're at home or on the go, code, upload and access your projects anytime from your browser <b>for free.</b>",
      cta: 'Go to Cloud Editor',
      gettingStarted: 'Learn more',
    },
  },
  crumbs: {
    Guide: 'Iniziamo',
  },
};
