import React from 'react';
import { useTranslation } from 'react-i18next';

import './bannerCreate.scss';
import webide from '../../assets/webide.png';

const gettingStartedURL =
  'https://blog.arduino.cc/2024/01/17/your-classic-arduino-ide-experience-now-in-the-cloud?custom_banner=cloud_banner';
const webIdeURL = 'https://app.arduino.cc/sketches?custom_banner=cloud_banner';

const BannerCreate = () => {
  const { t } = useTranslation();

  return (
    <div id="banner-create">
      <div className="intro">
        <h3>{t('software:createBanner:title')}</h3>
        <div dangerouslySetInnerHTML={{ __html: t('software:createBanner:description') }}></div>
        <div className="actions">
          <a className="primary-button" href={webIdeURL} target="_blank" rel="noreferrer">
            {t('software:createBanner:cta')}
          </a>
          <a className="tertiary-button" href={gettingStartedURL} target="_blank" rel="noreferrer">
            {t('software:createBanner:gettingStarted')}
          </a>
        </div>
      </div>
      <img src={webide} alt={t('software.createBanner.title')} />
    </div>
  );
};

export default BannerCreate;
