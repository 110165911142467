import React from 'react';

import './toc.scss';

const TocItem = ({ item }) => {
  const nestedItems = (item.items || []).map(item => {
    return <TocItem key={item.url} item={item} />;
  });

  return (
    <li>
      <a href={item.url}>{item.title}</a>
      {item.items ? <ul>{nestedItems}</ul> : null}
    </li>
  );
};

const Toc = ({ toc, title, children }) => (
  <div className="toc">
    {title ? <h5>{title}</h5> : null}

    <ul>{children ? children : toc.items.map(item => <TocItem key={item.url} item={item} />)}</ul>
  </div>
);

export default Toc;
