import React from 'react';
import PropTypes from 'prop-types';

import './downloadTitle.scss';

const DownloadTitle = ({ children, id, title, caption }) => {
  return (
    <>
      <div className="anchor-bait" id={id} />
      <h2 className="download-title-wrapper" id={id}>
        <span className="download-title-prefix">{children}</span>
        <span className="download-title">
          {title}
          {caption && <span className="download-title-caption">{caption}</span>}
        </span>
      </h2>
    </>
  );
};

DownloadTitle.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  caption: PropTypes.string,
};

export default DownloadTitle;
