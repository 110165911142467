import { navigate } from '@reach/router';

export const triggerDownload = downloadUrl => {
  // the user likely refreshed the page at the wrong time: divert them to the download page
  if (!downloadUrl) {
    navigate('/en/software/download-thank-you');
    return;
  }

  // increment download counter; don't care about the result
  countDownload();

  // trigger the download
  window.location.href = downloadUrl;
};

export const countDownload = () => {
  return fetch(process.env.GATSBY_DOWNLOADS_COUNTER_URI, { method: 'POST' });
};
