import React from 'react';

import './tutorialHomeBanner.scss';

const TutorialBanner = ({ title, link, children, color }) => {
  const [svg, ...content] = children;
  return (
    <a className="tutorials-home-box" href={link}>
      <div className="container-img" style={{ backgroundColor: color }}>
        {svg}
      </div>
      <div className="container-text">
        <h3>{title}</h3>
        {content}
      </div>
    </a>
  );
};

export default TutorialBanner;
