import React, { useState, useEffect } from 'react';

import './downloadCount.scss';

const DownloadCount = () => {
  const [downloads, setDownloads] = useState(0);

  useEffect(() => {
    fetch(process.env.GATSBY_DOWNLOADS_COUNTER_URI)
      .then(res => res.json())
      .then(res => {
        setDownloads(res.downloads);
      });
  }, []);

  return <span className="download-count">{downloads.toLocaleString()}</span>;
};

export default DownloadCount;
