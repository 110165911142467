import { IconStatusAttentionErrorOutlineFilled } from '@bcmi-labs/react-icons';
import React from 'react';

import './errorMessage.scss';

const ErrorMessage = ({ children }) => {
  return (
    <label className="error-message">
      <IconStatusAttentionErrorOutlineFilled />
      {children}
    </label>
  );
};

export default ErrorMessage;
