import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import './lastBuild.scss';

const LastBuild = ({ children }) => {
  const { t } = useTranslation();

  const [lastUpdate, setLastUpdate] = useState('23 July 2020 16:25:17 GMT');
  useEffect(() => {
    fetch('https://downloads.arduino.cc/hourly_build_epoch.json')
      .then(res => res.json())
      .then(res => {
        const oldest = Math.min(...res.filter(o => !o._ignore).map(o => o.last_modified * 1000), +new Date());
        setLastUpdate(format(new Date(oldest), "dd MMM yyyy, HH:mm:ss 'GMT'"));
      });
  }, []);
  return (
    <div className="last-build">
      <span className="title">{children}</span>: <span>{lastUpdate}</span>
    </div>
  );
};

export default LastBuild;
